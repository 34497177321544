
import '@/assets/styles/imports/modal-stock.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VehicleInterface } from '@/interfaces/vehicle'
import Vehicle = VehicleInterface.Vehicle
import { TaskInterface } from '@/interfaces/task'
import Patch = TaskInterface.Patch
import api from '@/api'
import storage from '@/storage'
import { PaymentProvider, PaymentScriptParams} from "@/api/payment";

@Component
export default class ModalStock extends Vue {
  @Prop({ type: Number }) readonly id!: number
  @Prop({ type: Object }) readonly car!: Vehicle
  @Prop({ type: Array, default: () => [] }) readonly patches!: Array<Patch>
  @Prop({ type: Number }) readonly patchesPrice!: number
  @Prop(Array) readonly errors!: Array<TaskInterface.Dtc>
  @Prop({ type: Number }) readonly errorsPrice!: number
  @Prop({ type: Number }) readonly total!: number
  @Prop({ type: String, default: '$' }) readonly currency!: string
  paymentScriptParams: null | PaymentScriptParams<PaymentProvider>= null
  runPaymentTimer = false
  provider: PaymentProvider = 'Robocassa'
  isDiscount = false
  requestCount = 120
  isProdamusSuccess = false
  isProdamusError = false

  @Prop({ type: Number, default: 0 }) readonly discount!: number

  closeModal () {
    this.$emit('close')
  }

  async submitAction() {
    if(this.total === 0) {
      await api.task.process(this.id)
      this.$emit('close')
    } else {
      if (this.provider === 'Prodamus') {
        this.$emit('start-prodamus-payment')
        this.addProdamusListeners()
        const paymentScriptParams = this.paymentScriptParams as PaymentScriptParams<'Prodamus'>
        // @ts-expect-error
        // eslint-disable-next-line
        payformInit('ecudtc.payform.ru', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_sum: paymentScriptParams.sum,
          currency: paymentScriptParams.currency,
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_id: paymentScriptParams.order_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          order_num: paymentScriptParams.order_num,
          // eslint-disable-next-line @typescript-eslint/camelcase
          customer_phone: paymentScriptParams.customer_phone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          customer_email: paymentScriptParams.customer_email
        })
      } else {
        // @ts-expect-error
        // eslint-disable-next-line
        Robokassa.StartPayment(this.paymentScriptParams)
      }
      await this.checkStatusTask(this.id)
    }
  }

  getPaymentParams () {
    //console.log( JSON.stringify({ ...this.paymentScriptParams, Encoding: 'utf-8', Culture: this.$i18n.locale }));
    return JSON.stringify({ ...this.paymentScriptParams, Encoding: 'utf-8', Culture: this.$i18n.locale })
  }

  async checkStatusTask (taskId: number) {
    storage.lastPaymentId = taskId
    this.runPaymentTimer = true
    const payment = await this.checkPaymentStatus(taskId)
    if (!payment) return
    //await api.task.process(taskId)
    this.closeModal()
  }

  async checkPaymentStatus (taskId: number): Promise<boolean> {
    for (let i = 0; i < this.requestCount; i++) {
      if (!this.runPaymentTimer) return false
      const status = await api.payment.getTaskStatus(taskId)
      if (status === 'Paid') return true
      if (i === this.requestCount - 1 || status === 'Error') this.closeModal()
      await this.timeout()
    }
    return false
  }

  timeout (): Promise<null> {
    return new Promise(resolve => { setTimeout(resolve, 4000) })
  }

  eventProdamus (event: any) {
    if (event.data.status === "close") {
      if (this.isProdamusSuccess) return
      this.$emit('cancel-prodamus-payment')
      if (this.isProdamusError) {
        this.$emit('prodamus-error')
      }
    }
    if (event.data.status === "success") {
      this.isProdamusSuccess = true
    }
    if (event.data.status === "error") {
      this.isProdamusError = true
    }
  }

  addProdamusListeners () {
    window.addEventListener("message", this.eventProdamus)
  }

  removeProdamusListeners () {
    window.addEventListener("message", this.eventProdamus)
  }

  get selectPatches (): Array<Patch> {
    return this.patches.filter((patch: Patch) => patch.isSelected)
  }

  get checkedErrorsNames (): string {
    const checkedList: Array<string> = []
    for (let i = 0, len = this.errors.length; i < len; i++) {
      const patch = this.errors[i]
      if (patch.isSel) checkedList.push(patch.dtc)
    }
    if (checkedList.length) return checkedList.join(', ')
    return ''
  }
  async created () {
    this.provider = await api.payment.getDefaultProvider() || 'Robocassa'
    if (this.provider === 'Robocassa') {
      this.paymentScriptParams = await api.payment.getPaymentScriptParams<'Robocassa'>(this.id, this.provider)
    } else {
      this.paymentScriptParams = await api.payment.getPaymentScriptParams<'Prodamus'>(this.id, this.provider)
    }
    if (!this.paymentScriptParams) this.$emit('order-error')
  }
  destroyed () {
    this.runPaymentTimer = false
    this.removeProdamusListeners()
  }
}
