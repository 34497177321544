
import '@/assets/styles/imports/dtc-add.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TaskInterface } from '@/interfaces/task'
import Dtc = TaskInterface.Dtc

@Component
export default class DtcAdd extends Vue {
  @Prop(Array) readonly errors!: Array<Dtc>
  @Prop({ type: Number, default: 0 }) readonly price!: number
  @Prop({ type: Number, default: 0 }) readonly priceDiscount!: number
  @Prop({ type: String, default: '$' }) readonly currency!: string
  @Prop({ type: String, default: '' }) readonly billingDescription!: string

  search = ''

  eventChangeErrors () {
    this.$emit('change-errors')
  }

  get list (): Array<Dtc> {
    if (!this.search) return this.errors
    const search = new RegExp(this.search.toUpperCase())
    return this.errors.filter(error => search.test(error.dtc.toUpperCase()))
  }

  get selectErrors () {
    const errors: Array<string> = []
    this.errors.forEach(error => {
      if (error.isSel) return errors.push(error.dtc)
      if (!error.canSel) return errors.push(error.dtc)
    })
    return errors.join(', ')
  }

  get total (): string {
    if (this.priceDiscount) return `${this.priceDiscount} ${this.currency}`
    if (this.price) return `${this.price} ${this.currency}`
    return ''
  }
}
