
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { TaskInterface } from "@/interfaces/task";
import Task = TaskInterface.Task;
import ModalStock from "@/components/_modal/ModalStock.vue";
import ModalDownload from "@/components/_modal/ModalDownload.vue";
import FilterEcuDisabled from "@/components/_inner/FilterEcuDisabled.vue";
import Dtc from "@/components/_inner/Dtc.vue";
import DtcAdd from "@/components/_inner/DtcAdd.vue";
import Patches from "@/components/_inner/Patches.vue";
import BuyPanel from "@/components/_inner/BuyPanel.vue";
import BuySubscription from "@/components/_inner/BuySubscription.vue";
import getCurrencySymbol from "@/additionally/getCurrencySymbol";
import api from "@/api";
import storage from "@/storage";
import getLangIdObject from "@/additionally/getLangIdObject";
import SubscriptionSolution from "@/components/_inner/SubscriptionSolution.vue";
import ResultPanel from "@/components/_inner/ResultPanel.vue";
import ModalProcess from "@/components/_modal/ModalProcess.vue";
import ModalMmcError from "@/components/_modal/ModalMmcError.vue";

type Payback = {
  done: Function;
};

@Component<OrderCustomPage>({
  beforeRouteEnter(to: Route, from: Route, next: Function) {
    const taskId: number = +to.params.id;
    if (!taskId) return next({ name: "Order", params: { lang: to.params.lang }});
    api.task.edit({ taskId }).then((task: Task | null) => {
      if (!task) return next({ name: "404", params: { lang: to.params.lang }});
      next((vm: OrderCustomPage) => {
        vm.task = task;
      });
    });
  },
  components: {
    ModalMmcError,
    ModalProcess,
    ModalStock,
    ModalDownload,
    FilterEcuDisabled,
    Dtc,
    DtcAdd,
    Patches,
    ResultPanel
  },
  metaInfo() {
    return {
      title: this.add
        ? this.$tc("captionAdd", 1, { n: this.task?.taskId })
        : this.$tc("caption", 1, { n: this.task?.taskId }),
    };
  },
})
export default class OrderCustomPage extends Vue {
  showPayModal = false;
  showDownloadModal = false;
  task: Task | null = null;
  dtcBillingDescription = "";
  dtcBillingNote = "";
  downloadToken = "";
  subscribtionId = null;
  isEmptyForm = true;
  isShowProcessPayment = false;
  isShowModalError = false;

  @Watch("$i18n.locale")
  onWatchLocales() {
    this.addTitle();
    this.getBillingInfo();
  }

  addTitle() {
    //document.title = this.add ? this.$tc('captionAdd', 1, { n: this.task?.taskId }) : this.$tc('caption', 1, { n: this.task?.taskId })
  }

  getDescriptionData() {
    return this.task?.subscription;
  }

  getBillingInfo() {
    if (this.task) {
      const billingDescriptions: TaskInterface.BillingDescriptions = getLangIdObject<
        TaskInterface.BillingDescriptions
      >(this.task.billingDescriptions);

      this.dtcBillingDescription = billingDescriptions.tdcBillingDescription;
      this.dtcBillingNote = billingDescriptions.tdcBillingNote;
      //this.promoDescription = billingDescriptions.promoDescription;
    }
  }

  checkEmptyForm(task: any) {
    function isEmptyForm(task: any) {
      if (
        task.patches.find((item: any) => item.isSelected) ||
        task.taskDtcCurrent.items.find((item: any) => item.isSel)
      ) {
        return false;
      }

      return true;
    }

    this.isEmptyForm = isEmptyForm(task);
  }

  async calcPrice(task = this.task) {
    this.checkEmptyForm(task);
    if (!task) return;
    const newTask = await api.task.calcPriceV2(task);
    this.refreshTask(newTask);
  }

  refreshTask(task: TaskInterface.Task | null) {
    if (!task) return alert("Error");
    this.task = task;
    storage.lastTaskVehicle = task ? task.vehicle : null;
    this.getBillingInfo();
  }

  refreshPromoCode(code: string) {
    if (!this.task) return;
    this.task.promoCode = code;
    this.calcPrice();
  }

  async eventSaveAndOpenModal() {
    const isAuth = await api.authorize.isAuthenticated();
    if (!isAuth)
      return this.$router.push({
        name: "Login",
        params: { lang: this.$route.params.lang },
      });
    const saved = await this.saveTask();
    if (saved) return this.openPayModal();
    alert("Error save task");
  }

  async saveTask(
    payback: Payback = {
      done: () => {
        // console.log(
        //   "typescript + vue2 = вот такие вот костыли приходиться писать, лень разбираться как это чинить"
        // );
      },
    }
  ): Promise<boolean> {
    if (!this.task) return false;
    const task = await api.task.save(this.task);
    this.refreshTask(task);

    this.$nextTick(() => {
      payback.done();
    });
    return !!task;
  }

  openPayModal() {
    this.showPayModal = true;
  }

  closePayModal() {
    //// @ts-expect-error
    //// eslint-disable-next-line
    //Robokassa.ClosePaymentForm();
    this.showPayModal = false;
    this.closeProcessPayment()
    this.openDownloadModal();
  }

  async openDownloadModal() {
    if (!this.task) return;
    const status = await api.payment.getTaskStatus(this.task.taskId);
    if (status !== "Paid") return;
    await this.getTokenAndOpenModal()
  }

  async getTokenAndOpenModal () {
    if (!this.task) return;
    const token: string | null = await api.task.getDownloadLink(
        this.task.taskId
    );
    if (!token) return;
    this.downloadToken = token;
    this.showDownloadModal = true;
  }

  closeDownloadModal() {
    this.showDownloadModal = false;
    this.$router.push({
      name: "History",
      params: { lang: this.$route.params.lang },
    });
  }

  showProcessPayment () {
    this.isShowProcessPayment = true
  }

  closeProcessPayment () {
    this.isShowProcessPayment = false
  }

  showModalError() {
    this.isShowModalError = true
  }

  closeModalError() {
    this.isShowModalError = false
  }

  eventError () {
    this.showModalError()
    this.closePayModal()
    this.closeProcessPayment()
  }

  get add(): boolean {
    if (!this.task) return false;
    if (this.task.status === "Paid") return true;
    return this.task.status === "Processed";
  }

  get currency(): string {
    if (!this.task) return "";
    return getCurrencySymbol(this.task.currencyId);
  }

  get total(): number {
    return this.price - this.discount;
  }

  get price(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalPriceSum) sum += this.task.patchesTotalPriceSum;
    if (this.task.taskDtcCurrentTotalPriceSum)
      sum += this.task.taskDtcCurrentTotalPriceSum;
    return sum;
  }

  get discount(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalDiscountSum)
      sum += this.task.patchesTotalDiscountSum;
    if (this.task.taskDtcCurrentTotalDiscountSum)
      sum += this.task.taskDtcCurrentTotalDiscountSum;
    return sum;
  }

  timeout (): Promise<null> {
    return new Promise(resolve => { setTimeout(resolve, 4000) })
  }

  async mounted() {
    this.getBillingInfo();
    this.addTitle();
    this.checkEmptyForm(this.task);
  }
}
