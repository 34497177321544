
import '@/assets/styles/imports/filter-ecu.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { VehicleInterface } from '@/interfaces/vehicle'
import Vehicle = VehicleInterface.Vehicle

@Component
export default class FilterEcuDisabled extends Vue {
  @Prop({ type: Object }) car!: Vehicle
}
